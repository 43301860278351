import React from 'react'
import { useDesktopMediaQuery } from '../../utils/hooks/useMediaQueries'
import {
  MainWrapper,
  ErrorTitle,
  ErrorMessage,
  DisabledLinkIcon,
} from './ErrorStates.styles'

export default function DisabledLink() {
  const isDesktop = useDesktopMediaQuery()
  return (
    <>
      <MainWrapper>
        <DisabledLinkIcon name="link-off" size={24} color="white" />
        <ErrorTitle $isDesktop={isDesktop}>This link was disabled</ErrorTitle>
        <ErrorMessage $isDesktop={isDesktop}>
          The link you’re trying to access was disabled and can no longer be
          accessed. Please get in touch with the link owner to access this link.
        </ErrorMessage>
      </MainWrapper>
    </>
  )
}
