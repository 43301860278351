import React from 'react'
import { StyledChip } from './Pill.styles'

type PillTypes = {
  title: string
  icon?: React.ReactNode
  iconPosition?: string
  onPress: () => void
  disabled?: boolean
  disabledStyle?: React.CSSProperties
}

export default function Pill({
  title,
  icon,
  iconPosition,
  onPress,
  disabled,
  disabledStyle,
}: PillTypes) {
  return (
    <StyledChip
      onPress={onPress}
      icon={icon}
      iconPosition={iconPosition}
      title={title}
      disabled={disabled}
      disabledStyle={disabledStyle}
    />
  )
}
