export const subMenus = {
  languages: {
    items: [
      'English',
      'Spanish',
      'Italian',
      'Nederlans',
      'Portugues',
      'Francais',
      'Suomi',
      'Dansk',
    ],
    headerText: 'Select Language',
  },
}
