import React, { useEffect, useState } from 'react'
import { createDrawerNavigator } from '@react-navigation/drawer'
import { NavigationContainer } from '@react-navigation/native'
import * as Linking from 'expo-linking'
import { Dimensions } from 'react-native'
import { useTheme } from 'styled-components/native'

import IndexPage from '../../../pages'
import CustomDrawer from 'components/CustomDrawer'

const prefix = Linking.createURL('/')
const DrawerNavigator = createDrawerNavigator()

const linkingOptions = {
  prefixes: [prefix, 'https://*.netlify.app'],
  config: {
    screens: {
      player: '/player/:shareId',
      about: '/about',
      NotFound: '*',
    },
  },
}

const window = Dimensions.get('window')
const screen = Dimensions.get('screen')

export default function RootStack() {
  const theme = useTheme()
  const [dimensions, setDimensions] = useState({ window, screen })

  useEffect(() => {
    const subscription = Dimensions.addEventListener(
      'change',
      ({ window, screen }) => {
        setDimensions({ window, screen })
      }
    )
    return () => subscription?.remove()
  })

  return (
    <NavigationContainer linking={linkingOptions}>
      <DrawerNavigator.Navigator
        drawerContent={(props) => <CustomDrawer {...props} />}
        initialRouteName="player"
        screenOptions={{
          drawerStyle: {
            width: dimensions.window.width,
            backgroundColor: theme.colors.body,
          },
          headerShown: false,
          drawerActiveTintColor: theme.colors.text,
          drawerInactiveTintColor: theme.colors.cerulean,
          drawerActiveBackgroundColor: 'none',
          drawerType: 'front',
          drawerLabelStyle: { fontFamily: 'NunitoSans', fontSize: 16 },
        }}
      >
        <DrawerNavigator.Screen
          name="player"
          component={IndexPage}
          options={{
            drawerLabel: 'Home',
            drawerItemStyle: { display: 'none' },
          }}
        />
      </DrawerNavigator.Navigator>
    </NavigationContainer>
  )
}
