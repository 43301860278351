import React, { useRef } from 'react'
import * as WebBrowser from 'expo-web-browser'
import {
  MenuContainer,
  MenuWrapper,
  MenuList,
  MenuItem,
  OptionsIcon,
} from './Menu.styles'
import { useDesktopMediaQuery } from '../../../utils/hooks/useMediaQueries'
import { useDetectOutsideClick } from '../../../utils/hooks/useDetectOutsideClick'

export default function Menu({ navigation }) {
  const isDesktop = useDesktopMediaQuery()
  const dropdownRef = useRef(null)
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false)

  const handlePressButtonAsync = async (url: string) => {
    await WebBrowser.openBrowserAsync(url)
  }

  const handleOptionsIconPress = () => {
    if (isDesktop) {
      setIsActive(!isActive)
    } else {
      navigation.toggleDrawer()
    }
  }
  return (
    <MenuContainer ref={dropdownRef}>
      <OptionsIcon
        name="more-horizontal"
        size={32}
        color="white"
        onPress={handleOptionsIconPress}
      />
      {isActive && (
        <MenuWrapper>
          <MenuList>
            <MenuItem
              onPress={() => handlePressButtonAsync('https://www.plaympe.com/')}
            >
              About
            </MenuItem>
          </MenuList>
          <MenuList>
            <MenuItem
              cursor="pointer"
              onPress={() =>
                handlePressButtonAsync('https://www.plaympe.com/terms/')
              }
            >
              Terms and Conditions
            </MenuItem>
          </MenuList>
          <MenuList>
            <MenuItem
              cursor="pointer"
              onPress={() =>
                handlePressButtonAsync(
                  'https://support.plaympe.com/portal/en/newticket?departmentId=348910000000006907&layoutId=348910000000074011'
                )
              }
            >
              Contact Support
            </MenuItem>
          </MenuList>
        </MenuWrapper>
      )}
    </MenuContainer>
  )
}
