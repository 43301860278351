import { useState, useEffect } from 'react'
import LanguagesList from './LanguagesList'
import { MaterialIcons as Icon } from '@expo/vector-icons'
import { useTheme } from 'styled-components/native'
import { StyledSearchBar } from './Languages.styles'

export default function Languages({ items }) {
  const [searchString, setSearchString] = useState('')
  const [filteredItems, setFilteredItems] = useState([])
  const theme = useTheme()

  useEffect(() => {
    const newItems = getFilteredLanguagesList(searchString)
    setFilteredItems(newItems)
  }, [searchString])

  const getFilteredLanguagesList = (search: string) => {
    return items.filter((i: string) =>
      i.toLowerCase().includes(search.toLowerCase())
    )
  }

  return (
    <>
      <StyledSearchBar
        round
        placeholder="Search"
        onChangeText={(newVal) => setSearchString(newVal)}
        value={searchString}
        clearIcon={
          <Icon
            name="clear"
            color={theme.colors.text}
            size={22}
            onPress={() => setSearchString('')}
          />
        }
        searchIcon={<Icon name="search" color={theme.colors.text} size={24} />}
      />
      <LanguagesList items={filteredItems} />
    </>
  )
}
