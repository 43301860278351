import styled from 'styled-components/native'
import { SearchBar } from '@rneui/base'

export const StyledSearchBar = styled(SearchBar).attrs(({ theme }) => ({
  containerStyle: {
    backgroundColor: 'unset',
    paddingLeft: '12px',
    paddingRight: '12px',
    borderBottomColor: theme.colors.body,
    borderTopColor: theme.colors.body,
  },
  inputContainerStyle: {
    backgroundColor: theme.colors.body,
    borderWidth: 1,
    borderColor: theme.colors.text,
    borderBottomWidth: 1,
  },
  inputStyle: {
    fontFamily: 'NunitoSans',
    fontSize: 16,
    marginLeft: 5,
  },
}))``
