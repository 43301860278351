import React from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'
import { useTheme } from 'styled-components/native'
import { Octicons as OctIcon } from '@expo/vector-icons'
import DurationSlider from '../../components/DurationSlider'
import TrackControls from 'components/TrackControls'
import {
  BackgroundImage,
  Duration,
  DurationBox,
  Wrapper,
  Header,
  TrackWrapper,
  BackButton,
  FallBackIcon,
} from './NowPlaying.styles'
import {
  TrackTitle,
  ArtistName,
} from '../../components/TrackBar/TrackBar.styles'
import { SharerName } from 'features/Player/Player.styles'
import { useTabletAndAboveMediaQuery } from '../../utils/hooks/useMediaQueries'
import { RootState } from 'store'

type NowPlayingProps = {
  handleModalVisibility: (isOpen: boolean) => void
}

export default function NowPlaying({ handleModalVisibility }: NowPlayingProps) {
  const theme = useTheme()
  const { preview, playback } = useSelector((state: RootState) => state.player)
  const { sound, status } = useSelector(
    (state: RootState) => state.player.playback
  )
  const isTabletAbove = useTabletAndAboveMediaQuery()

  function formatMiliseconds(milis?: number): string {
    if (milis == null || isNaN(milis)) {
      return '-:--:--'
    }
    let seconds = milis / 1000
    let minutes = Math.floor(seconds / 60)
    seconds = seconds % 60
    return `${String(minutes)}:${seconds.toFixed(2)}`
  }

  const goBack = () => {
    handleModalVisibility(false)
  }

  return (
    <Wrapper>
      <BackgroundImage
        source={{ uri: preview?.cover_art_url }}
        resizeMode="cover"
        blurRadius={3}
        imageStyle={{
          height: '100%',
          filter: 'brightness(50%)',
          background: !preview?.cover_art_url ? theme.colors.body : 'unset',
        }}
      >
        <Header>
          <BackButton
            name="chevron-down-sharp"
            size={24}
            cursor="pointer"
            onPress={goBack}
          />
          <SharerName>{`Sent by ${preview?.sharer_name}`}</SharerName>
          <OctIcon
            name="kebab-horizontal"
            size={24}
            style={{ color: '#fff' }}
          />
        </Header>
        {!preview?.cover_art_url && (
          <FallBackIcon
            source={{ uri: require('assets/images/music-note-fallback.png') }}
          />
        )}

        <TrackWrapper>
          <TrackTitle $size="20px">{playback?.currentTrack?.title}</TrackTitle>
          <ArtistName $size="14px">{playback?.currentTrack?.artist}</ArtistName>

          <View style={{ height: 50, marginBottom: 30 }}>
            <DurationBox>
              <Duration>{formatMiliseconds(status?.positionMillis)}</Duration>
              <Duration>{formatMiliseconds(status?.durationMillis)}</Duration>
            </DurationBox>

            <DurationSlider
              sound={sound}
              status={status}
              style={{ width: '100%', height: 5 }}
            />
          </View>
          <TrackControls
            style={{
              justifyContent: isTabletAbove ? 'space-around' : 'space-between',
            }}
          />
        </TrackWrapper>
      </BackgroundImage>
    </Wrapper>
  )
}
