import { useState } from 'react'
import { isEmpty } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { View, TouchableWithoutFeedback, Modal } from 'react-native'

import { RootState, AppDispatch } from 'store'
import { setMasterVolume } from 'features/Player/Player.slice'

import { useTheme } from 'styled-components/native'
import usePlayBack from 'features/Player/usePlayBack'
import { useDesktopMediaQuery } from '../../utils/hooks/useMediaQueries'

import DurationSlider from '../DurationSlider'
import TrackControls from '../TrackControls'
import VolumeSlider from 'components/VolumeSlider'
import NowPlaying from 'features/NowPlaying'

import { AlbumArt } from 'features/Player/Player.styles'

import {
  LinearGradientWrapper,
  TrackTitle,
  ArtistName,
  PlayIcon,
  TrackControlsWrapper,
  Row,
} from './TrackBar.styles'

const TrackBar = () => {
  const theme = useTheme()
  const [isNowPlayingOpen, setIsNowPlayingOpen] = useState(false)
  const dispatch: AppDispatch = useDispatch()
  const isDesktop = useDesktopMediaQuery()
  const { toggleTrackPlay } = usePlayBack()
  const { playback, preview } = useSelector((state: RootState) => state.player)
  const albumCoverURI = preview?.cover_art_url
    ? preview?.cover_art_url
    : require('assets/images/default-album-cover.png')

  const onTrackbarPress = () => {
    if (isDesktop) {
      return
    }
    setIsNowPlayingOpen(true)
  }

  const handleVolumeSliderChange = (value: number) => {
    const volume = value.toFixed(2)
    if (playback?.sound !== null) {
      playback?.sound.setStatusAsync({ volume })
      dispatch(setMasterVolume(volume))
    }
  }

  if (isEmpty(playback?.sound)) {
    return null
  }
  return (
    <>
      <TouchableWithoutFeedback onPress={onTrackbarPress}>
        <View>
          <DurationSlider
            sound={playback?.sound}
            status={playback?.status}
            style={{ width: '100%', height: 5, zIndex: 2 }}
          />
          <LinearGradientWrapper
            colors={[theme.colors.quartz, theme.colors.chineseBlack]}
            $isDesktop={isDesktop}
          >
            <AlbumArt source={{ uri: albumCoverURI }} width={50} height={50} />
            <View style={{ paddingLeft: 12, justifyContent: 'space-around' }}>
              <TrackTitle $isDesktop={isDesktop}>
                {playback?.currentTrack?.title}
              </TrackTitle>
              <ArtistName>{playback?.currentTrack?.artist}</ArtistName>
            </View>

            {!isDesktop && (
              <PlayIcon
                size={32}
                cursor="pointer"
                name={playback?.status?.isPlaying ? 'pause' : 'play'}
                onPress={toggleTrackPlay}
              />
            )}

            {isDesktop && (
              <>
                <TrackControlsWrapper>
                  <TrackControls style={{ width: 300, alignSelf: 'center' }} />
                </TrackControlsWrapper>

                <Row>
                  <VolumeSlider onSliderChange={handleVolumeSliderChange} />
                </Row>
              </>
            )}
          </LinearGradientWrapper>
        </View>
      </TouchableWithoutFeedback>
      <Modal
        visible={isNowPlayingOpen}
        animationType="slide"
        onRequestClose={() => setIsNowPlayingOpen(false)}
      >
        <NowPlaying handleModalVisibility={setIsNowPlayingOpen} />
      </Modal>
    </>
  )
}

export default TrackBar
