import Svg, { SvgProps, Ellipse } from 'react-native-svg'

const Spinner = ({ fill }: SvgProps) => (
  <Svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <Ellipse
      cx="20.665"
      cy="12.2175"
      rx="1.52216"
      ry="1.55525"
      transform="rotate(90 20.665 12.2175)"
      fill={fill}
    />
    <Ellipse
      cx="18.1511"
      cy="18.3678"
      rx="1.41343"
      ry="1.44416"
      transform="rotate(90 18.1511 18.3678)"
      fill={fill}
    />
    <Ellipse
      cx="18.151"
      cy="6.06704"
      rx="1.63088"
      ry="1.66633"
      transform="rotate(90 18.151 6.06704)"
      fill={fill}
    />
    <Ellipse
      cx="5.62714"
      cy="18.3679"
      rx="1.19598"
      ry="1.22198"
      transform="rotate(90 5.62714 18.3679)"
      fill={fill}
    />
    <Ellipse
      cx="5.5566"
      cy="6.02007"
      rx="0.869803"
      ry="0.888712"
      transform="rotate(90 5.5566 6.02007)"
      fill={fill}
    />
    <Ellipse
      cx="11.7778"
      cy="20.9154"
      rx="1.3047"
      ry="1.33307"
      transform="rotate(90 11.7778 20.9154)"
      fill={fill}
    />
    <Ellipse
      cx="11.7782"
      cy="3.51939"
      rx="1.73961"
      ry="1.77742"
      transform="rotate(90 11.7782 3.51939)"
      fill={fill}
    />
    <Ellipse
      cx="2.89058"
      cy="12.2174"
      rx="1.08725"
      ry="1.11089"
      transform="rotate(90 2.89058 12.2174)"
      fill={fill}
    />
  </Svg>
)

export default Spinner
