import styled from 'styled-components/native'
import { ImageBackground, View, Text, Image } from 'react-native'
import { Ionicons as IonIcon } from '@expo/vector-icons'

export const Wrapper = styled(View)`
  flex: 1;
  color: ${({ theme }) => theme.colors.body};
`

export const BackgroundImage = styled(ImageBackground)`
  flex: 1;
  padding: 0 15px;
  height: 100%;
`
export const Duration = styled(Text)`
  font-size: 14px;
  font-weight: bold;
  font-family: NunitoSans;
  color: ${({ theme }) => theme.colors.text};
`

export const DurationBox = styled(View)`
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`

export const Header = styled(View)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 52px 12px 8px;
`

export const TrackWrapper = styled(View)`
  padding: 8px;
  flex: 1;
  justify-content: center;
`

export const BackButton = styled(IonIcon)`
  color: ${({ theme }) => theme.colors.text};
`

export const FallBackIcon = styled(Image)`
  display: flex;
  align-self: center;
  width: 45px;
  height: 67px;
  position: absolute;
  top: 20%;
`
