import { createSlice } from '@reduxjs/toolkit'
import {
  initSession,
  logWholeShareActivity,
  logTrackActivity,
} from './Session.thunks'

export const sessionSlice = createSlice({
  name: 'session',
  initialState: {
    status: 'idle',
    error: null,
    session: {
      sessionId: '',
    },
    undoStack: {},
  },
  reducers: {
    syncSession(state, { payload }) {
      state.session = payload
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(initSession.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(initSession.fulfilled, (state, { payload }) => {
        state.status = 'done'

        state.session = payload
      })
      .addCase(initSession.rejected, (state) => {
        state.status = 'rejected'
      })
      .addCase(logWholeShareActivity.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(logWholeShareActivity.fulfilled, (state, { payload }) => {
        state.status = 'done'
      })
      .addCase(logWholeShareActivity.rejected, (state) => {
        state.status = 'rejected'
      })
      .addCase(logTrackActivity.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(logTrackActivity.fulfilled, (state, { payload }) => {
        state.status = 'done'
      })
      .addCase(logTrackActivity.rejected, (state) => {
        state.status = 'rejected'
      }),
})

export const { syncSession } = sessionSlice.actions

export default sessionSlice.reducer
