import { Platform } from 'react-native'
import * as Sharing from 'expo-sharing'
import * as FileSystem from 'expo-file-system'

export default function useSharing() {
  const handleDownload = async (url: string) => {
    const canShare = await Sharing.isAvailableAsync()

    if (Platform.OS === 'web') {
      const downloadLink = document.createElement('a')

      downloadLink.href = url
      downloadLink.click()
    }

    if (canShare) {
      const { uri } = await FileSystem.downloadAsync(
        url,
        `${FileSystem.cacheDirectory}release_download.mp3`
      )

      Sharing.shareAsync(uri, {
        mimeType: 'audio/mpeg',
        dialogTitle: `Saving: ${'Super serve Song'}`,
      })
    }
  }

  return {
    handleDownload,
  }
}
