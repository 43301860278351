import React, { useEffect, useRef, useState } from 'react'
import { View } from 'react-native'
import { isEmpty } from 'lodash'
import { Button } from '@rneui/themed'
import { useTheme } from 'styled-components/native'
import { useSelector, useDispatch } from 'react-redux'
import { useSpring, animated } from '@react-spring/native'
import {
  SharerName,
  AlbumArt,
  AlbumName,
  Genre,
  Artist,
} from 'features/Player/Player.styles'
import {
  Wrapper,
  GenreList,
  ActionBar,
  Title,
  AlbumDetailsWrapper,
  DownloadButtonsRow,
  SpinnerRow,
} from './PreviewDetails.styles'
import { TrackControl } from 'components/TrackControls/TrackControls.styles'
import Alert from 'components/Alert'
import {
  useTabletAndAboveMediaQuery,
  useMobileMediaQuery,
  useDesktopMediaQuery,
} from '../../utils/hooks/useMediaQueries'
import { RootState, AppDispatch } from 'store'
import { logWholeShareActivity } from 'features/Session/Session.thunks'
import { getZipDownloadStatus } from 'features/Player/Player.thunks'
import { setIsPreparingZipDownload } from 'features/Player/Player.slice'
import {
  ACTIVITY_LOG_EVENT_TYPES,
  PLAYBACK_FORMATS,
  CLEAR_ZIP_DOWNLOAD_INTERVAL_MILISECONDS,
  ZIP_DOWNLOAD_INTERVAL_MILISECONDS,
} from '../../utils/constants'
import { getDownloadEventType } from '../../utils/helpers'
import Spinner from 'components/Spinner'
import usePlayBack from 'features/Player/usePlayBack'
import useSharing from '../../utils/hooks/useSharing'

interface handleDownloadButtonPressTypes {
  value: string
  isReady: boolean
}

export default function PreviewDetails() {
  const theme = useTheme()
  const [selectedZipFormat, setSelectedZipFormat] = useState('wav')
  const intervalRef = useRef<ReturnType<typeof setInterval>>(null)
  const timerRef = useRef<ReturnType<typeof setTimeout>>(null)
  const isDesktop = useDesktopMediaQuery()
  const isTabletAbove = useTabletAndAboveMediaQuery()
  const isMobile = useMobileMediaQuery()
  const dispatch: AppDispatch = useDispatch()
  const { handleTrackPlay, toggleTrackPlay } = usePlayBack()
  const { handleDownload } = useSharing()
  const AnimatedView = animated(View)
  const [showDownloadError, setShowDownloadError] = useState(false)
  const { session } = useSelector((state: RootState) => state.session)
  const { preview, playback, zipDownloadInterval, isPreparingZipDownload } =
    useSelector((state: RootState) => state.player)

  const albumCoverURI = preview?.cover_art_url
    ? preview?.cover_art_url
    : require('assets/images/default-album-cover.png')

  const spinnerAnimation = useSpring({
    loop: true,
    from: {
      rotate: '0deg',
    },
    to: {
      rotate: '360deg',
    },
    config: { mass: 1, tension: 100, friction: 40 },
  })

  const spinnerWrapperProps = {
    maxWidth: '30px',
    transform: [{ rotate: spinnerAnimation.rotate }],
  }
  const removeInterval = (interval) => {
    interval && clearInterval(interval)
  }

  const playAll = () => {
    if (isEmpty(playback.sound)) {
      const firstTrack = preview.tracks[0]

      dispatch(
        logWholeShareActivity({
          eventType: ACTIVITY_LOG_EVENT_TYPES.STREAM,
          sessionId: session?.sessionId,
          shareId: preview?.share_id,
        })
      )

      return handleTrackPlay(firstTrack, 0)
    }
    toggleTrackPlay()
  }

  const initiateZipDownloadInterval = () => {
    intervalRef.current = setInterval(() => {
      dispatch(
        getZipDownloadStatus({
          shareId: preview?.share_id,
        })
      )
    }, ZIP_DOWNLOAD_INTERVAL_MILISECONDS)
  }

  const initiateTimeOutForIntervalRemoval = () => {
    timerRef.current = setTimeout(() => {
      removeInterval(intervalRef.current)
    }, CLEAR_ZIP_DOWNLOAD_INTERVAL_MILISECONDS)
  }

  const handleDownloadButtonPress = ({
    value,
    isReady,
  }: handleDownloadButtonPressTypes) => {
    if (!isReady) {
      setSelectedZipFormat(value)
      dispatch(setIsPreparingZipDownload(true))
      initiateZipDownloadInterval()
      initiateTimeOutForIntervalRemoval()
      return
    }

    const zipDownloadUrl = preview?.zipped[value]?.url
    const logEventType = getDownloadEventType(value)

    handleDownload(zipDownloadUrl)
    dispatch(
      logWholeShareActivity({
        eventType: logEventType,
        sessionId: session?.sessionId,
        shareId: preview?.share_id,
      })
    )
  }

  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current)
      removeInterval(intervalRef.current)
    }
  }, [])

  useEffect(() => {
    if (!isPreparingZipDownload) {
      removeInterval(intervalRef.current)
    }
  }, [isPreparingZipDownload])

  useEffect(() => {
    if (zipDownloadInterval.status === 'complete') {
      handleDownloadButtonPress({
        value: selectedZipFormat,
        isReady: preview?.zipped.wav?.ready,
      })
    }
    if (zipDownloadInterval.status === 'failed') {
      setShowDownloadError(true)
    }
  }, [zipDownloadInterval.status])

  return (
    <>
      {showDownloadError && (
        <Alert
          isDesktop={isDesktop}
          isMobile={isMobile}
          message="Unable to download file"
          description="Please refresh your page and try again. If the issue persists, please contact support."
          type="error"
          closable
          onClose={() => setShowDownloadError(false)}
        />
      )}

      <Wrapper $isTabletAbove={isTabletAbove}>
        <View>
          <SharerName>{`Sent by ${preview?.sharer_name}`}</SharerName>
          <AlbumArt
            source={{ uri: albumCoverURI }}
            height={344}
            width={isTabletAbove ? 344 : null}
            style={{ marginBottom: 10, marginTop: 6 }}
          />
        </View>

        <AlbumDetailsWrapper $isTabletAbove={isTabletAbove}>
          {isTabletAbove && (
            <Title style={{ fontSize: 14, marginBottom: 8 }}>ALBUM</Title>
          )}
          <AlbumName $isDesktop={isDesktop} style={{ fontSize: 24 }}>
            {preview?.title}
          </AlbumName>
          <Artist style={{ fontSize: 16, lineHeight: 35 }}>
            {preview?.artist}
          </Artist>

          <GenreList $isDesktop={isDesktop}>
            {preview?.genres.map((item) => (
              <Genre key={item}>{item},</Genre>
            ))}
          </GenreList>

          <ActionBar>
            <TrackControl
              name={
                playback.status?.isPlaying
                  ? 'pause-circle-sharp'
                  : 'play-circle-sharp'
              }
              size={40}
              cursor="pointer"
              onPress={playAll}
            />
          </ActionBar>
          {!preview?.stream_only && (
            <DownloadButtonsRow>
              <Button
                disabled={isPreparingZipDownload}
                title="Download WAV"
                size="sm"
                titleStyle={{ color: theme.colors.neutralW20 }}
                disabledTitleStyle={{ color: theme.colors.neutralW50 }}
                onPress={() =>
                  handleDownloadButtonPress({
                    value: PLAYBACK_FORMATS.WAV,
                    isReady: preview?.zipped.wav?.ready,
                  })
                }
              />
              <Button
                disabled={isPreparingZipDownload}
                title="Download MP3"
                type="outline"
                size="sm"
                disabledStyle={{ backgroundColor: 'unset' }}
                disabledTitleStyle={{ color: theme.colors.neutralW40 }}
                onPress={() =>
                  handleDownloadButtonPress({
                    value: PLAYBACK_FORMATS.MP3,
                    isReady: preview?.zipped.mp3?.ready,
                  })
                }
              />
            </DownloadButtonsRow>
          )}

          {isPreparingZipDownload && (
            <SpinnerRow>
              <Title>Preparing your music for download</Title>
              <AnimatedView
                style={{
                  ...spinnerWrapperProps,
                  marginLeft: 10,
                }}
              >
                <Spinner fill={theme.colors.player50} />
              </AnimatedView>
            </SpinnerRow>
          )}
        </AlbumDetailsWrapper>
      </Wrapper>
    </>
  )
}
