import styled from 'styled-components/native'
import { Text, View, TouchableOpacity, Image } from 'react-native'
import { MaterialIcons as Icon } from '@expo/vector-icons'
import { Entypo as EntypoIcon } from '@expo/vector-icons'
import { Button } from '@rneui/themed'

const DESKTOP_COLUMNS = '30px 2fr 2fr 1fr 2fr'
const TABLET_COLUMNS = '30px 2fr 2fr 1fr 2fr'
const MOBILE_COLUMNS = '30px 3fr'

const DESKTOP_COLUMNS_STREAM_ONLY = '30px 469px 549px 1fr'
const TABLET_COLUMNS_STREAM_ONLY = '30px 4.5fr 4fr 1fr'

const getGridColumns = (isDesktop, isTabletAbove, isStreamOnly) => {
  const mediaQuery = isDesktop ? 'desktop' : isTabletAbove ? 'tablet' : 'mobile'

  switch (mediaQuery) {
    case 'desktop':
      return isStreamOnly ? DESKTOP_COLUMNS_STREAM_ONLY : DESKTOP_COLUMNS
    case 'tablet':
      return isStreamOnly ? TABLET_COLUMNS_STREAM_ONLY : TABLET_COLUMNS
    case 'mobile':
      return MOBILE_COLUMNS
    default:
      return DESKTOP_COLUMNS
  }
}

export const TrackListWrapper = styled(View)`
  margin-top: 32px;
`

export const TrackWrapper = styled(TouchableOpacity)`
  margin-bottom: ${({ $isTabletAbove }) => ($isTabletAbove ? '24px' : '0px')};
  align-items: ${({ $isTabletAbove }) => ($isTabletAbove ? 'center' : 'start')};
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: ${({ $isDesktop, $isTabletAbove, $isStreamOnly }) =>
    getGridColumns($isDesktop, $isTabletAbove, $isStreamOnly)};
`

export const TrackIcon = styled(Icon)`
  padding: 20px 20px 0 0;
  color: ${({ theme }) => theme.colors.blueberry};
`
export const TrackOnPauseIcon = styled(Icon)`
  padding: 20px 20px 0 0;
  color: ${({ theme }) => theme.colors.blueberry};
`

export const TrackNumber = styled(Text)`
  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-weight: bold;
  font-family: NunitoSans;
  color: ${({ theme }) => theme.colors.text};
  margin-left: 6px;
`
export const TrackColumn = styled(View)`
  display: flex;
  flex-direction: column;
`

export const RowText = styled(Text)`
  font-size: 16px;
  font-weight: bold;
  font-family: NunitoSans;
  color: ${({ theme }) => theme.colors.text};
  width: 177px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const ArtistRow = styled(View)`
  margin-top: 4px;
  flex-direction: row;
  align-items: center;
`
export const ExplicitIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.text};
  margin-right: 7px;
`

export const Artist = styled(Text)`
  font-size: 14px;
  font-family: NunitoSans;
  color: ${({ theme }) => theme.colors.light_gray};
`

export const GridHeaderGroup = styled(View)`
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: ${({ $isDesktop, $isTabletAbove, $isStreamOnly }) =>
    getGridColumns($isDesktop, $isTabletAbove, $isStreamOnly)};
`
export const Serial = styled(Text)`
  font-size: 12px;
  font-family: NunitoSans;
  padding-bottom: 10px;
  color: ${({ theme }) => theme.colors.text};
`
export const GridHeader = styled(Text)`
  font-size: 12px;
  font-family: NunitoSans;
  padding-bottom: 10px;
  color: ${({ theme }) => theme.colors.text};
`
export const DownloadTitle = styled(Text)`
  font-size: 12px;
  font-family: NunitoSans;
  padding-bottom: 10px;
  color: ${({ theme }) => theme.colors.text};
  text-align: right;
`

export const ListDivider = styled(Text)`
  width: auto;
  height: 0.3px;
  margin: 16.1px 0 24.6px 1.5px;
  border: #848484;
  grid-column: 1 / -1;
`
export const TrackSummary = styled(View)`
  display: flex;
  flex-direction: row;
  grid-column: 1 / -1;
`
export const TotalTracks = styled(Text)`
  font-size: 12px;
  font-family: NunitoSans;
  color: ${({ theme }) => theme.colors.text};
`
export const TotalDuration = styled(Text)`
  font-size: 12px;
  font-family: NunitoSans;
  color: ${({ theme }) => theme.colors.text};
`
export const DotDivider = styled(EntypoIcon)`
  padding-right: 0 2px 0 2px;
  color: ${({ theme }) => theme.colors.text};
`
export const Duration = styled(Text)`
  font-size: 14px;
  padding-left: 10px;
  display: flex;
  justify-self: center;
  color: ${({ theme }) => theme.colors.text};
`

export const PillsColumn = styled(View)`
  flex-direction: row;
  gap: 15px;
  justify-content: ${({ $isTabletAbove }) =>
    $isTabletAbove ? 'end' : 'start'};
`

export const TrackDivider = styled(Text)`
  width: auto;
  height: 0.3px;
  margin: 24px 0;
  border: ${({ theme }) => theme.colors.neutralW30};
  grid-column: 1 / -1;
`
export const CodeColumn = styled(View)`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

export const CanadianFlag = styled(Image)`
  width: 18px;
  height: 18px;
  margin-right: 7px;
`

export const CopyButton = styled(Button).attrs(
  ({ theme, $hasCopiedFromThisRow }) => ({
    buttonStyle: {
      backgroundColor: theme.colors.neutralW30,
      width: $hasCopiedFromThisRow ? 74 : 44,
      height: 24,
      marginLeft: 8,
      padding: 8,
    },
    size: 'sm',
    iconPosition: 'right',
  })
)``
