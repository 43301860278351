import styled from 'styled-components/native'
import { View } from 'react-native'

export const MainWrapper = styled(View)`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.body};
  width: 100%;
`

export const ContentWrapper = styled(View)`
  flex: 1;
  padding: 50px 16px 0;
  width: ${({ $isDesktop }) => ($isDesktop ? '1142px' : '100%')};
  max-width: 1142px;
  align-self: center;
`

export const Header = styled(View)`
  flex-direction: row;
  justify-content: space-between;
`

export const MenuContainer = styled(View)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`
