export const SKIP_TIME_MILLISECONDS = 15000
export const CLEAR_ZIP_DOWNLOAD_INTERVAL_MILISECONDS = 1800000
export const ZIP_DOWNLOAD_INTERVAL_MILISECONDS = 15000
export const ACTIVITY_LOG_EVENT_TYPES = {
  STREAM: 'stream',
  DOWNLOAD_WAV: 'download_wav',
  DOWNLOAD_MP3: 'download_mp3',
}
export const PLAYBACK_FORMATS = {
  WAV: 'wav',
  MP3: 'mp3',
}
