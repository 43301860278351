import styled from 'styled-components/native'
import { View, Text } from 'react-native'
import { MaterialIcons as Icon } from '@expo/vector-icons'

export const DrawerItemLabelStyles = {
  fontFamily: 'NunitoSans',
  fontSize: 16,
  color: '#fff',
}

export const Header = styled(View)`
  height: 100px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  padding: 40px 15px;
`

export const BackButton = styled(Icon)`
  margin-right: auto;
`
export const ItemWrapper = styled(View)`
  flex-direction: row;
  align-items: center;
  padding-right: 8px;
`

export const ForwardIcon = styled(Icon)`
  font-size: 28px;
  color: ${({ theme }) => theme.colors.text};
`

export const HeaderTitle = styled(Text)`
  color: ${({ theme }) => theme.colors.text};
  font-size: 16px;
  font-weight: bold;
  margin-right: auto;
`
