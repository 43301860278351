import styled from 'styled-components/native'
import { Text, View, TouchableOpacity } from 'react-native'
import { Feather as Icon } from '@expo/vector-icons'

export const MenuContainer = styled(View)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const MenuWrapper = styled(TouchableOpacity)`
  background-color: #ffffff;
  border-radius: 8px;
  position: absolute;
  top: 40px;
  right: 0;
  width: 275px;
  cursor: pointer;
  opacity: 1;
  visibility: visible;
`
export const OptionsIcon = styled(Icon)`
  padding-bottom: 20px;
`

export const MenuList = styled(View)`
  text-decoration: none;
  padding: 15px 20px;
  display: block;
  cursor: pointer;
`
export const MenuItem = styled(Text)`
  font-weight: 700;
  font-size: 18px;
  color: #009ccf;
  margin: 0 10px;
  cursor: pointer;
`
