import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { View } from 'react-native'
import { useTheme } from 'styled-components/native'
import { Button } from '@rneui/themed'
import {
  TrackWrapper,
  TrackIcon,
  TrackOnPauseIcon,
  TrackNumber,
  TrackColumn,
  RowText,
  ArtistRow,
  ExplicitIcon,
  Artist,
  Duration,
  GridHeader,
  PillsColumn,
  TrackDivider,
  CodeColumn,
  CanadianFlag,
  CopyButton,
} from './TrackList.styles'
import { MaterialIcons as Icon } from '@expo/vector-icons'
import Pill from 'components/Pill'
import {
  useTabletAndAboveMediaQuery,
  useDesktopMediaQuery,
  useMobileMediaQuery,
} from '../../utils/hooks/useMediaQueries'
import useSharing from '../../utils/hooks/useSharing'
import { getDownloadEventType } from '../../utils/helpers'
import { logTrackActivity } from 'features/Session/Session.thunks'
import { RootState, AppDispatch } from 'store'
import ConditionalWrapper from 'components/ConditionalWrapper'

type Track = {
  track_id: string
  title: string
  artist: string
  explicit: boolean
  cancon: boolean
  download_mp3_url: string
  download_wav_url: string
  isrc: string
}

type TrackTypes = {
  index: number
  key: number
  data: Track
  time: string
  isCurrentTrack: boolean
  isPlaybackPlaying: boolean
  onPress: () => void
  handleCopyButtonClick: ({ text, trackId }: handleCopyButtonTypes) => void
  trackIdFromCopy: string
}

type HandleDownloadTypes = {
  url: string
  format: string
}
type handleCopyButtonTypes = {
  text: string
  trackId: string
}

export default function Track({
  index,
  isCurrentTrack,
  isPlaybackPlaying: isAnyPlaybackPlaying,
  onPress,
  data,
  time,
  handleCopyButtonClick,
  trackIdFromCopy,
}: TrackTypes) {
  const theme = useTheme()
  const dispatch: AppDispatch = useDispatch()
  const isMobile = useMobileMediaQuery()
  const isTabletAbove = useTabletAndAboveMediaQuery()
  const isDesktop = useDesktopMediaQuery()
  const { handleDownload } = useSharing()
  const { preview, playback } = useSelector((state: RootState) => state.player)
  const { session } = useSelector((state: RootState) => state.session)
  const hasCopiedFromThisRow = trackIdFromCopy === data?.track_id

  const handleTrackDownload = ({ url, format }: HandleDownloadTypes) => {
    const eventType = getDownloadEventType(format)

    handleDownload(url)
    dispatch(
      logTrackActivity({
        eventType,
        sessionId: session?.sessionId,
        shareId: preview?.share_id,
        assetTrackId: data?.track_id,
        audioPosition: playback.status.positionMillis || 0,
      })
    )
  }

  return (
    <TrackWrapper
      onPress={onPress}
      $isTabletAbove={isTabletAbove}
      $isDesktop={isDesktop}
      $isStreamOnly={preview?.stream_only}
    >
      {isCurrentTrack && isAnyPlaybackPlaying && (
        <TrackIcon name="multitrack-audio" size={24} />
      )}

      {isCurrentTrack && !isAnyPlaybackPlaying && (
        <TrackOnPauseIcon name="remove" size={24} />
      )}

      {!isCurrentTrack && <TrackNumber>{index + 1}</TrackNumber>}

      <ConditionalWrapper
        condition={isMobile}
        wrapper={(children) => <View>{children}</View>}
      >
        <TrackColumn>
          <RowText>{data?.title}</RowText>
          <ArtistRow>
            {data?.explicit && <ExplicitIcon name="explicit" size={18} />}
            {data?.cancon && (
              <CanadianFlag
                source={{ uri: require('assets/images/canadian-flag.svg') }}
              />
            )}
            <Artist>{data?.artist}</Artist>
          </ArtistRow>
        </TrackColumn>

        {isMobile && <GridHeader style={{ marginTop: 16 }}>ISRC:</GridHeader>}
        <CodeColumn>
          <RowText style={{ width: 'auto' }}>{data?.isrc || '-'}</RowText>

          {data?.isrc && (
            <CopyButton
              title={hasCopiedFromThisRow ? 'Copied' : 'Copy'}
              onPress={() =>
                handleCopyButtonClick({
                  text: data?.isrc,
                  trackId: data?.track_id,
                })
              }
              $hasCopiedFromThisRow={hasCopiedFromThisRow}
              icon={
                hasCopiedFromThisRow && (
                  <Icon
                    name="check"
                    size={14}
                    color="white"
                    style={{ marginLeft: 5 }}
                  />
                )
              }
            />
          )}
        </CodeColumn>

        {isTabletAbove && <Duration>{time}</Duration>}
        {!preview?.stream_only && (
          <>
            {isMobile && (
              <GridHeader style={{ marginTop: 16 }}>Download as:</GridHeader>
            )}
            <PillsColumn $isTabletAbove={isTabletAbove}>
              <Pill
                onPress={() =>
                  handleTrackDownload({
                    url: data?.download_wav_url,
                    format: 'wav',
                  })
                }
                title="WAV"
                icon={<Icon name="file-download" size={14} color="white" />}
                iconPosition="right"
                disabled={preview?.stream_only}
                disabledStyle={{
                  background: theme.colors.cerulean,
                  borderColor: theme.colors.neutralW80,
                }}
              />

              <Pill
                onPress={() =>
                  handleTrackDownload({
                    url: data?.download_mp3_url,
                    format: 'mp3',
                  })
                }
                title="MP3"
                icon={<Icon name="file-download" size={14} color="white" />}
                iconPosition="right"
                disabled={preview?.stream_only}
                disabledStyle={{
                  background: theme.colors.cerulean,
                  borderColor: theme.colors.neutralW80,
                }}
              />
            </PillsColumn>
          </>
        )}
      </ConditionalWrapper>

      {isMobile && <TrackDivider />}
    </TrackWrapper>
  )
}
