import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import PreviewDetails from 'components/PreviewDetails'
import TrackList from 'components/TrackList'
import { RootState } from 'store'
import usePlayBack from './usePlayBack'
import { LayoutWrapper } from './Player.styles'
import { useDesktopMediaQuery } from '../../utils/hooks/useMediaQueries'

export default function Player() {
  const isDesktop = useDesktopMediaQuery()
  const { sound, status } = useSelector(
    (state: RootState) => state.player?.playback
  )
  const { playNext } = usePlayBack()

  useEffect(() => {
    return sound
      ? () => {
          sound.unloadAsync()
        }
      : undefined
  }, [sound])

  useEffect(() => {
    if (status?.didJustFinish) {
      playNext()
    }
  }, [status.didJustFinish])

  return (
    <>
      {!isDesktop ? (
        <>
          <PreviewDetails />
          <TrackList />
        </>
      ) : (
        <LayoutWrapper>
          <PreviewDetails />
          <TrackList />
        </LayoutWrapper>
      )}
    </>
  )
}
