import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

type Session = {
  sessionId: string
  shareId: string
}
axios.defaults.withCredentials = true

//Temporary until we figure out how to load env variables with expo sdk 44
const API_URL =
  window.location.hostname === 'share.plaympe.com'
    ? 'https://api.plaympe.com/assets/superserve'
    : 'https://api.epmyalptest.com/assets/superserve'

export const initSession = createAsyncThunk(
  'session/initSession',
  async ({ sessionId, shareId }: Session, { rejectWithValue }) => {
    try {
      const { data, status } = await axios.post(`${API_URL}/session`, {
        session_id: sessionId,
        share_id: shareId,
      })

      if (status === 200) {
        return data.data
      }
    } catch (error) {
      return rejectWithValue(error?.response)
    }
  }
)

type ShareActivity = {
  eventType: string
  sessionId: string
  shareId: string
}

export const logWholeShareActivity = createAsyncThunk(
  'session/logWholeShareActivity',
  async (
    { eventType, sessionId, shareId }: ShareActivity,
    { rejectWithValue }
  ) => {
    try {
      const { data, status } = await axios.post(`${API_URL}/share/log`, {
        event_type: eventType,
        session_id: sessionId,
        share_id: shareId,
      })

      if (status === 200) {
        return data.data
      }
    } catch (error) {
      return rejectWithValue(error?.response)
    }
  }
)

type TrackActivity = {
  eventType: string
  sessionId: string
  shareId: string
  assetTrackId: string
  audioPosition: number
}

export const logTrackActivity = createAsyncThunk(
  'session/logTrackActivity',
  async (
    {
      eventType,
      sessionId,
      shareId,
      assetTrackId,
      audioPosition,
    }: TrackActivity,
    { rejectWithValue }
  ) => {
    try {
      const { data, status } = await axios.post(`${API_URL}/log`, {
        event_type: eventType,
        session_id: sessionId,
        share_id: shareId,
        asset_track_id: assetTrackId,
        audio_position: audioPosition,
      })

      if (status === 200) {
        return data.data
      }
    } catch (error) {
      return rejectWithValue(error?.response)
    }
  }
)
