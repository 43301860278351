import styled from 'styled-components/native'
import { View, Text, Image } from 'react-native'

type AlbumArtProps = {
  readonly height: string
  readonly width: string
}

type AlbumNameProps = {
  readonly $isDesktop: boolean
}

export const LayoutWrapper = styled(View)`
  z-index: -1;
`

export const Row = styled(View)`
  flex-direction: row;
  align-self: stretch;
  justify-content: center;
  align-items: center;
`

export const SharerName = styled(Text)`
  font-size: 12px;
  font-family: NunitoSans;
  color: ${({ theme }) => theme.colors.gray};
  margin-bottom: 5px;
`

export const AlbumArt = styled(Image)<AlbumArtProps>`
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  height: ${({ height }) => (height ? `${height}px` : '100%')};
`
export const AlbumName = styled(Text)<AlbumNameProps>`
  font-size: 20px;
  font-weight: 700;
  font-family: NunitoSans;
  color: ${({ theme }) => theme.colors.text};
  max-width: ${({ $isDesktop }) => ($isDesktop ? '700px' : '350px')};
`
export const Genre = styled(Text)`
  font-size: 14px;
  font-family: NunitoSans;
  color: #b9b9b9;
  margin-right: 5px;
`
export const Artist = styled(Text)`
  font-size: 14px;
  font-family: NunitoSans;
  color: ${({ theme }) => theme.colors.text};
  margin-bottom: 4px;
`
