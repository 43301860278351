import styled from 'styled-components/native'
import { View, Text } from 'react-native'
import { MaterialIcons as DisabledIcon } from '@expo/vector-icons'
import { MaterialCommunityIcons as BrokenIcon } from '@expo/vector-icons'

export const MainWrapper = styled(View)`
  display: flex;
  align-items: center;
  padding-top: 150px;
`
export const ErrorTitle = styled(Text)`
  display: flex;
  justify-content: center;
  font-size: ${({ $isDesktop }) => ($isDesktop ? '36px' : '24px')};
  font-weight: 700;
  font-family: NunitoSansBold;
  margin-bottom: 12px;
  color: ${({ theme }) => theme.colors.text};
`
export const BrokenLinkIcon = styled(BrokenIcon)`
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ae0101;
  border-radius: 100%;
  margin-bottom: 12px;
`
export const DisabledLinkIcon = styled(DisabledIcon)`
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #393939;
  border-radius: 100%;
  margin-bottom: 12px;
`

export const ErrorMessage = styled(Text)`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-family: NunitoSans;
  max-width: ${({ $isDesktop }) => ($isDesktop ? '400px' : '300px')};
  color: ${({ theme }) => theme.colors.text};
`
