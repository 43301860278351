import styled from 'styled-components/native'
import { Text, View } from 'react-native'
import { LinearGradient } from 'expo-linear-gradient'
import { Ionicons as Icon } from '@expo/vector-icons'

const DESKTOP_GRID_TEMPLATE_COLUMNS = '60px 1fr 2fr 1fr'
const TABLET_GRID_TEMPLATE_COLUMNS = '30px 3fr 80px 1fr'
const MOBILE_GRID_TEMPLATE_COLUMNS = '55px 1fr 40px'

type TrackTitleProps = {
  readonly $size: string
}

type AlbumNameProps = {
  readonly $size: string
}

export const LinearGradientWrapper = styled(LinearGradient)`
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: ${({ $isDesktop, $isTabletAbove }) =>
    $isDesktop
      ? DESKTOP_GRID_TEMPLATE_COLUMNS
      : $isTabletAbove
      ? TABLET_GRID_TEMPLATE_COLUMNS
      : MOBILE_GRID_TEMPLATE_COLUMNS};
  align-items: center;
  justify-content: space-around;
  background-color: #333333;
  height: 75px;
  padding: 0 20px;
`

export const TrackTitle = styled(Text)<TrackTitleProps>`
  font-family: NunitoSansBold;
  font-size: ${({ $size }) => ($size ? $size : '16px')};
  color: ${({ theme }) => theme.colors.text};
  width: ${({ $isDesktop }) => ($isDesktop ? '100%' : '1fr')};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const ArtistName = styled(Text)<AlbumNameProps>`
  font-family: NunitoSans;
  font-size: ${({ $size }) => ($size ? $size : '14px')};
  margin-top: 5px;
  color: ${({ theme }) => theme.colors.text};
`
export const Row = styled(View)`
  flex-direction: row;
  align-self: stretch;
  justify-content: end;
  align-items: center;
`

export const PlayIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.text};
  margin-left: auto;
`

export const TrackControlsWrapper = styled(View)`
  flex: 1;
`
