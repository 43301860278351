import React from 'react'
import { useDesktopMediaQuery } from '../../utils/hooks/useMediaQueries'
import {
  MainWrapper,
  BrokenLinkIcon,
  ErrorTitle,
  ErrorMessage,
} from './ErrorStates.styles'

export default function BrokenLink() {
  const isDesktop = useDesktopMediaQuery()
  return (
    <>
      <MainWrapper>
        <BrokenLinkIcon name="exclamation-thick" size={30} color="white" />
        <ErrorTitle $isDesktop={isDesktop}>Unable to load link</ErrorTitle>
        <ErrorMessage $isDesktop={isDesktop}>
          We were unable to load the link you’re trying to access. Please
          double-check that your link is correct and try again.
        </ErrorMessage>
      </MainWrapper>
    </>
  )
}
