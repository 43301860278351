import React, { useEffect } from 'react'
import { useRoute } from '@react-navigation/native'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import { isEmpty } from 'lodash'
import { getPreview } from 'features/Player/Player.thunks'
import { initSession } from 'features/Session'
import { syncSession } from 'features/Session/Session.slice'

import Layout from 'components/Layout'
import TrackBar from 'components/TrackBar'
import Player from 'features/Player'
import BrokenLink from 'components/ErrorStates/BrokenLink'
import DisabledLink from 'components/ErrorStates/DisabledLink'
import useLocalStorage from '../utils/hooks/useLocalStorage'
import { AppDispatch, RootState } from 'store'

function IndexPage({ navigation }) {
  const route: any = useRoute()
  const [session, setSession] = useLocalStorage('session', {})
  const shareId = route.params?.shareId
  const dispatch: AppDispatch = useDispatch()
  const { preview, status } = useSelector((state: RootState) => state.player)

  const initiateSession = () => {
    if (isEmpty(session)) {
      try {
        const sessionId = uuidv4()

        dispatch(initSession({ sessionId, shareId }))
        setSession({ sessionId })
      } catch (error) {
        console.error(error)
      }
    }
  }

  const getShare = () => {
    try {
      dispatch(getPreview({ shareId: route.params?.shareId }))
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    getShare()
  }, [shareId])

  useEffect(() => {
    initiateSession()
    dispatch(syncSession(session))
  }, [shareId, session])

  return (
    <>
      <Layout navigation={navigation}>
        {status === 'loading' && <span>loading</span>}
        {status === 'forbidden' && <DisabledLink />}
        {status === 'broken' && <BrokenLink />}
        {preview.share_id && <Player />}
      </Layout>
      <TrackBar />
    </>
  )
}

export default IndexPage
