import { useMediaQuery } from 'react-responsive'

export const useDesktopMediaQuery = () =>
  useMediaQuery({ query: '(min-width: 1280px)' })

export const useTabletAndAboveMediaQuery = () =>
  useMediaQuery({ query: '(min-width: 768px)' })

export const useMobileMediaQuery = () =>
  useMediaQuery({ query: '(max-width: 767px)' })

export const useTabletLandscapeOnlyMediaQuery = () =>
  useMediaQuery({
    query: '(min-device-width: 768px) and (max-device-width: 1024px)',
  })
