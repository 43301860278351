import React from 'react'
import { useSelector } from 'react-redux'
import {
  TrackControlsRow,
  TrackControl,
  SkipWrapper,
  SkipAmount,
} from './TrackControls.styles'
import usePlayBack from 'features/Player/usePlayBack'
import { RootState } from 'store'

type TrackControlsProps = {
  style?: React.CSSProperties
}

export default function TrackControls({ style }: TrackControlsProps) {
  const { toggleTrackPlay, skipForward, skipBack, playNext, playPrevious } =
    usePlayBack()
  const { sound, status } = useSelector(
    (state: RootState) => state.player.playback
  )

  return (
    <TrackControlsRow style={{ ...style }}>
      <TrackControl
        name="md-play-skip-back-sharp"
        size={32}
        cursor="pointer"
        onPress={playPrevious}
      />
      <SkipWrapper>
        <SkipAmount>15</SkipAmount>
        <TrackControl
          name="reload-sharp"
          size={36}
          cursor="pointer"
          style={{ transform: [{ rotateY: '180deg' }], position: 'absolute' }}
          onPress={skipBack}
        />
      </SkipWrapper>

      <TrackControl
        name={status?.isPlaying ? 'pause-circle-sharp' : 'play-circle-sharp'}
        size={54}
        cursor="pointer"
        onPress={toggleTrackPlay}
      />
      <SkipWrapper>
        <SkipAmount>15</SkipAmount>
        <TrackControl
          name="reload-sharp"
          size={36}
          cursor="pointer"
          onPress={skipForward}
          style={{ position: 'absolute' }}
        />
      </SkipWrapper>

      <TrackControl
        name="md-play-skip-forward-sharp"
        size={32}
        cursor="pointer"
        onPress={playNext}
      />
    </TrackControlsRow>
  )
}
