import { View, Text, TouchableOpacity, Image } from 'react-native'
import { MaterialCommunityIcons as Icon } from '@expo/vector-icons'
import styled from 'styled-components/native'

const getBackgroundColor = (theme, alertType) => {
  switch (alertType) {
    case 'success':
      return theme.colors.success20
    case 'info':
      return theme.colors.interact20
    case 'warning':
      return theme.colors.alert20
    case 'error':
      return theme.colors.error20
    default:
      return theme.colors.neutralW100
  }
}

const getColor = (theme, alertType) => {
  switch (alertType) {
    case 'success':
      return theme.colors.neutralW100
    case 'info':
      return theme.colors.neutralW100
    case 'warning':
      return theme.colors.neutralW100
    case 'error':
      return theme.colors.neutralW100
    default:
      return theme.colors.neutralW10
  }
}

const getBorderColor = (theme, alertType) => {
  switch (alertType) {
    case 'success':
      return theme.colors.success70
    case 'info':
      return theme.colors.interact70
    case 'warning':
      return theme.colors.alert70
    case 'error':
      return theme.colors.error70
    default:
      return theme.colors.neutralW10
  }
}

export const Container = styled(View)`
  width: ${({ $isDesktop }) => (!$isDesktop ? '100%' : '632px')};
  height: auto;
  max-height: 116px;
  background-color: ${({ theme, $type }) => getBackgroundColor(theme, $type)};
  padding: 16px;
  border-radius: 10px;
  border-color: ${({ theme, $type }) => getBorderColor(theme, $type)};
  border-left-width: 8px;
  border-left-style: outset;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  align-items: flex-start;
  margin-bottom: 24px;
  border-width: 1px;
`

export const ContentWrapper = styled(View)`
  flex-direction: row;
  width: 100%;
`

export const TextWrapper = styled(View)`
  align-items: flex-start;
`

export const Message = styled(Text)`
  font-size: 16px;
  color: ${({ theme, $type }) => getColor(theme, $type)};
  text-align: center;
  line-height: 28px;
  font-family: 'NunitoSans';
  font-weight: 700;
`

export const Description = styled(Text)`
  font-size: 14px;
  font-family: 'NunitoSans';
  color: ${({ theme, $type }) => getColor(theme, $type)};
  text-align: left;
  width: ${({ $isMobile }) => ($isMobile ? '240px' : '550px')};
`

export const Button = styled(TouchableOpacity)`
  background-color: ${({ theme, $type }) => getBackgroundColor(theme, $type)};
  padding: 12px 16px;
  border-radius: 8px;
  align-items: center;
  margin-top: 16px;
`

export const ButtonText = styled(Text)`
  font-size: 16px;
  color: ${({ theme, $type }) => getColor(theme, $type)};
`

export const CloseButton = styled(TouchableOpacity)`
  position: absolute;
  right: 16px;
  top: 16px;
  z-index: 1;
`

export const CloseIcon = styled(Icon)`
  color: ${({ theme, $type }) => getColor(theme, $type)};
`
export const AlertIcon = styled(Icon)`
  margin-right: 16px;
  color: ${({ theme, $type }) => getColor(theme, $type)};
`
