import styled from 'styled-components/native'
import { Chip } from '@rneui/base'

export const StyledChip = styled(Chip).attrs(({ theme }) => ({
  containerStyle: {
    paddingLeft: 0,
    paddingRight: 0,
    padding: 0,
    borderRadius: 5,
    width: 62,
  },
  buttonStyle: {
    paddingLeft: 8,
    paddingRight: 8,
    paddingBottom: 3,
    paddingTop: 3,
    borderRadius: 5,
    backgroundColor: 'unset',
    borderWidth: 1,
    borderColor: theme.colors.neutralC80,
    borderStyle: 'solid',
    padding: '4px 8px',
  },
  titleStyle: {
    fontFamily: 'NunitoSans',
    fontSize: 12,
    fontWeight: 'normal',
  },
}))``
