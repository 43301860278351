import styled from 'styled-components/native'
import { View, Text } from 'react-native'

type WrapperProps = {
  readonly $isTabletAbove: boolean
}

type AlbumDetailsWrapperProps = {
  readonly $isTabletAbove: boolean
}

type GenreListProps = {
  readonly $isDesktop: boolean
}

export const Wrapper = styled(View)<WrapperProps>`
  flex-direction: ${({ $isTabletAbove }) =>
    $isTabletAbove ? 'row' : 'column'};
`

export const GenreList = styled(View)<GenreListProps>`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  max-width: ${({ $isDesktop }) => ($isDesktop ? '700px' : '350px')};
`

export const ActionBar = styled(View)`
  flex-direction: row;
  align-items: center;
  padding-top: 24px;
`

export const Title = styled(Text)`
  color: #fff;
  font-size: 12px;
`

export const AlbumDetailsWrapper = styled(View)<AlbumDetailsWrapperProps>`
  padding-top: ${({ $isTabletAbove }) => ($isTabletAbove ? '28px' : 0)};
  padding-left: ${({ $isTabletAbove }) => ($isTabletAbove ? '32px' : 0)};
`

export const DownloadButtonsRow = styled(View)`
  flex-direction: row;
  margin-top: 16px;
  width: 275px;
  justify-content: space-between;
`

export const SpinnerRow = styled(View)`
  flex-direction: row;
  align-items: center;
  margin-top: 13px;
`
