import styled from 'styled-components/native'
import { View, Text } from 'react-native'
import { Ionicons as Icon } from '@expo/vector-icons'

export const TrackControlsRow = styled(View)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
export const TrackControl = styled(Icon)`
  color: ${({ theme }) => theme.colors.text};
  margin: ${({ $margin }) => $margin || 0};
`

export const SkipWrapper = styled(View)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const SkipAmount = styled(Text)`
  font-size: 10px;
  color: ${({ theme }) => theme.colors.text};
`
