import i18n from 'i18n-js'
import * as Localization from 'expo-localization'

import en from './en.json'
import es from './es.json'

export default function config() {
  i18n.fallbacks = true
  i18n.locale = Localization.locale

  i18n.translations = {
    en,
    es,
  }
}
