import React from 'react'
import { useTheme } from 'styled-components/native'
import { useDispatch } from 'react-redux'
import Slider from '@react-native-community/slider'
import { setIsPlaying } from 'features/Player/Player.slice'
import { AppDispatch } from 'store'

export default function DurationSlider({ sound, status, style }) {
  const theme = useTheme()
  const dispatch: AppDispatch = useDispatch()
  const { durationMillis, positionMillis } = status

  const calculateSeekBar = () => {
    if (durationMillis && positionMillis) {
      return positionMillis / durationMillis
    }
    return 0
  }

  const handleSliderChange = (value: number) => {
    const newPositionMillis = durationMillis * value

    if (sound !== null) {
      sound.setStatusAsync({
        shouldPlay: true,
        positionMillis: Math.floor(newPositionMillis),
      })
      dispatch(setIsPlaying({ isPlaying: true }))
    }
  }

  return (
    <Slider
      style={{ ...style }}
      minimumValue={0}
      maximumValue={1}
      value={calculateSeekBar()}
      minimumTrackTintColor={theme.colors.cerulean}
      maximumTrackTintColor={theme.colors.text}
      onSlidingComplete={handleSliderChange}
    />
  )
}
