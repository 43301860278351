import { useDispatch, useSelector } from 'react-redux'
import {
  setIsPlaying,
  setPlayBackDuration,
  setPlayBackPosition,
  setCurrentTrack,
  setPlayBackStatus,
  setDidJustFinish,
} from './Player.slice'
import { AppDispatch, RootState } from 'store'
import { SKIP_TIME_MILLISECONDS } from '../../utils/constants'
import { getPlayback } from './Player.thunks'

export default function usePlayBack() {
  const dispatch: AppDispatch = useDispatch()
  const { playback, preview } = useSelector((state: RootState) => state.player)
  const { sound, status } = playback
  const currentTrackIndex = playback.currentTrack?.index

  const onPlaybackStatusUpdate = (status) => {
    dispatch(setPlayBackDuration(status?.durationMillis))
    dispatch(setPlayBackPosition(status?.positionMillis))
    dispatch(setIsPlaying({ isPlaying: status?.isPlaying }))
    dispatch(setDidJustFinish(status?.didJustFinish))
  }

  const fetchTrack = (url: string) => {
    return dispatch(
      getPlayback({
        stream_url: url,
        setPlayBackStatus,
      })
    ).unwrap()
  }

  const handleTrackPlay = async (track, index) => {
    const { sound } = await fetchTrack(track?.stream_url)

    sound.setStatusAsync({ shouldPlay: true, volume: playback?.masterVolume })
    sound.setOnPlaybackStatusUpdate(onPlaybackStatusUpdate)

    dispatch(setIsPlaying({ isPlaying: true }))
    dispatch(setCurrentTrack({ ...track, index }))
  }

  const toggleTrackPlay = () => {
    sound.setStatusAsync({ shouldPlay: !status?.isPlaying })
    sound.setOnPlaybackStatusUpdate(onPlaybackStatusUpdate)

    dispatch(setIsPlaying({ isPlaying: !status?.isPlaying }))
  }

  const muteTrack = () => {
    sound.setStatusAsync({ shouldPlay: false })
  }

  const skipForward = () => {
    const newPositionMillis = status?.positionMillis + SKIP_TIME_MILLISECONDS

    sound.setStatusAsync({
      positionMillis: Math.floor(newPositionMillis),
    })
  }

  const skipBack = () => {
    const newPositionMillis = status?.positionMillis - SKIP_TIME_MILLISECONDS

    sound.setStatusAsync({
      positionMillis: Math.floor(newPositionMillis),
    })
  }

  const playNext = async () => {
    let nextTrack = null
    let nextIndex = currentTrackIndex + 1

    const isLastAudio = nextIndex === preview.tracks.length

    if (isLastAudio) {
      nextIndex = 0
      nextTrack = preview.tracks[0]
    } else {
      nextTrack = preview.tracks[nextIndex]
    }

    handleTrackPlay(nextTrack, nextIndex)
  }

  const playPrevious = async () => {
    let nextTrack = null
    let nextIndex = currentTrackIndex - 1

    const isFirstAudio = nextIndex < 0

    if (isFirstAudio) {
      nextIndex = preview.tracks.length - 1
      nextTrack = preview.tracks[nextIndex]
    } else {
      nextTrack = preview.tracks[nextIndex]
    }

    handleTrackPlay(nextTrack, nextIndex)
  }

  return {
    toggleTrackPlay,
    muteTrack,
    skipForward,
    skipBack,
    playNext,
    fetchTrack,
    playPrevious,
    handleTrackPlay,
  }
}
