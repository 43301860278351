import { ACTIVITY_LOG_EVENT_TYPES } from '../utils/constants'

export function getTrackDuration(seconds) {
  const totalMinutes = Math.floor(seconds / 60)
  const totalSeconds = seconds % 60
  const formattedMinutes = totalMinutes
  const formattedSeconds = (totalSeconds < 10 ? '0' : '') + totalSeconds
  return `${formattedMinutes}:${formattedSeconds}`
}

export function getTotalTracksDuration(seconds) {
  const totalHours = Math.floor(seconds / 3600)
  const totalMinutes = Math.floor(seconds / 60)
  const totalSeconds = seconds % 60
  const formattedHours = totalHours > 0 ? totalHours + ' ' + 'hours' : ' '
  const formattedMinutes = totalMinutes + ' ' + 'min'
  const formattedSeconds =
    (totalSeconds < 10 ? '0' : ' ') + totalSeconds + ' ' + 'sec'
  return `${formattedHours} ${formattedMinutes} ${formattedSeconds}`
}

export const getDownloadEventType = (selectedFormat: string) => {
  switch (selectedFormat) {
    case 'mp3':
      return ACTIVITY_LOG_EVENT_TYPES.DOWNLOAD_MP3
    case 'wav':
      return ACTIVITY_LOG_EVENT_TYPES.DOWNLOAD_WAV

    default:
      break
  }
}
