import React, { useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import { Audio } from 'expo-av'
import { useFonts } from 'expo-font'
import { enableScreens } from 'react-native-screens'
import { store } from './store'
import i18n from './_i18n'
import RootStack from 'features/Navigation/RootStack'
import * as Linking from 'expo-linking'
import { ThemeProvider } from 'styled-components/native'
import { ThemeProvider as RNEThemeProvider } from '@rneui/themed'
import { darkTheme, lightTheme, RNEtheme } from './theme'

i18n()
enableScreens()
Audio.setAudioModeAsync({
  shouldDuckAndroid: true,
  playsInSilentModeIOS: true,
  staysActiveInBackground: true,
  interruptionModeIOS: Audio.INTERRUPTION_MODE_IOS_DUCK_OTHERS,
})

const App = () => {
  const [theme, setTheme] = useState('dark')
  const [data, setData] = useState(null)
  const [loaded] = useFonts({
    NunitoSans: require('./assets/fonts/NunitoSans-Regular.ttf'),
    NunitoSansBold: require('./assets/fonts/NunitoSans-Bold.ttf'),
    NunitoSansItalic: require('./assets/fonts/NunitoSans-Italic.ttf'),
    NunitoSansSemiBold: require('./assets/fonts/NunitoSans-SemiBold.ttf'),
    NunitoSansLight: require('./assets/fonts/NunitoSans-Light.ttf'),
    NunitoSansLightItalic: require('./assets/fonts/NunitoSans-LightItalic.ttf'),
  })
  const toggleTheme = async () => {
    const themeValue = theme === 'dark' ? 'light' : 'dark'
    setTheme(themeValue)
  }

  const handleDeepLink = (event) => {
    let data = Linking.parse(event.url)

    setData(data)
  }

  async function getInitialURL() {
    const initialURL = await Linking.getInitialURL()

    if (initialURL) setData(Linking.parse(initialURL))
  }

  useEffect(() => {
    Linking.addEventListener('url', handleDeepLink)

    if (!data) {
      getInitialURL()
    }

    return () => Linking.removeEventListener('url', handleDeepLink)
  }, [])

  if (!loaded) return null

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme === 'dark' ? darkTheme : lightTheme}>
        <RNEThemeProvider theme={RNEtheme}>
          <RootStack />
        </RNEThemeProvider>
      </ThemeProvider>
    </Provider>
  )
}

export default App
