import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { noop } from 'lodash'
import { Audio } from 'expo-av'

type getPlaybackTypes = { stream_url: string; setPlayBackStatus: typeof noop }

axios.defaults.withCredentials = true

//Temporary until we figure out how to load env variables with expo sdk 44
const API_URL =
  window.location.hostname === 'share.plaympe.com'
    ? 'https://api.plaympe.com/assets/superserve'
    : 'https://api.epmyalptest.com/assets/superserve'

export const getPlayback = createAsyncThunk(
  'player/getPlayBack',
  async (
    { stream_url, setPlayBackStatus }: getPlaybackTypes,
    { rejectWithValue }
  ) => {
    try {
      const { sound, status } = await Audio.Sound.createAsync(
        {
          uri: stream_url,
        },
        { shouldPlay: false },
        setPlayBackStatus
      )

      return { sound, status }
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

export const getPreview = createAsyncThunk(
  'player/getPreview',
  async ({ shareId }: { shareId: string }, { rejectWithValue }) => {
    try {
      const { data, status } = await axios.get(`${API_URL}/preview/${shareId}`)

      if (status === 200) {
        return data.data?.preview
      }
    } catch (error) {
      return rejectWithValue(error?.response)
    }
  }
)

export const getZipDownloadStatus = createAsyncThunk(
  'player/getZipDownloadStatus',
  async ({ shareId }: { shareId: string }, { rejectWithValue }) => {
    try {
      const { data, status } = await axios.get(`${API_URL}/zipped/${shareId}`)

      if (status === 200) {
        return data.data?.zipped
      }
    } catch (error) {
      return rejectWithValue(error?.response)
    }
  }
)
