import { createSlice } from '@reduxjs/toolkit'
import { getPlayback, getPreview, getZipDownloadStatus } from './Player.thunks'

export const playerSlice = createSlice({
  name: 'player',
  initialState: {
    status: 'idle',
    error: null,
    playback: {
      currentTrack: { index: null, title: '', artist: '' },
      sound: null,
      status: {
        positionMillis: 0,
        durationMillis: 0,
        volume: 1,
        isPlaying: false,
        isLoaded: false,
        didJustFinish: false,
      },
      masterVolume: 1,
    },
    preview: {
      share_id: '',
      cover_art_url: '',
      artist: '',
      title: '',
      sharer_name: '',
      genres: [],
      tracks: [],
      total_time: '',
      zipped: {
        wav: {
          ready: false,
          url: '',
        },
        mp3: {
          ready: false,
          url: '',
        },
      },
      stream_only: null,
    },
    isPreparingZipDownload: false,
    zipDownloadInterval: {
      status: 'idle',
    },
    undoStack: {},
  },
  reducers: {
    setPlayBackStatus({ playback }, { payload }) {
      playback.status = payload
    },
    setPlayBackPosition({ playback }, { payload }) {
      playback.status.positionMillis = payload
    },
    setPlayBackDuration({ playback }, { payload }) {
      playback.status.durationMillis = payload
    },
    setIsPlaying({ playback }, { payload }) {
      playback.status.isPlaying = payload?.isPlaying
    },
    setDidJustFinish({ playback }, { payload }) {
      playback.status.didJustFinish = payload
    },
    setMasterVolume({ playback }, { payload }) {
      playback.masterVolume = payload
    },
    setCurrentTrack({ playback }, { payload }) {
      playback.currentTrack = payload
    },
    setIsPreparingZipDownload(state, { payload }) {
      state.isPreparingZipDownload = payload
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getPlayback.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(getPlayback.fulfilled, (state, { payload }) => {
        state.status = 'done'

        state.playback = { ...state.playback, ...payload }
      })
      .addCase(getPlayback.rejected, (state) => {
        state.status = 'rejected'
      })
      .addCase(getPreview.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(getPreview.fulfilled, (state, { payload }) => {
        state.preview = payload
        state.status = 'done'
      })
      .addCase(getPreview.rejected, (state, { payload }) => {
        if (payload.status === 403) {
          state.status = 'forbidden'
        }
        if (payload.status === 404) {
          state.status = 'broken'
        }
      })
      .addCase(getZipDownloadStatus.fulfilled, (state, { payload }) => {
        const isPreparingDownload = !payload.mp3?.ready || !payload.wav?.ready

        state.preview.zipped = payload
        state.isPreparingZipDownload = isPreparingDownload
        state.zipDownloadInterval.status = isPreparingDownload
          ? 'active'
          : 'complete'
        state.status = 'done'
      })
      .addCase(getZipDownloadStatus.rejected, (state) => {
        state.zipDownloadInterval.status = 'failed'
        state.status = 'rejected'
      }),
})

export const {
  setPlayBackStatus,
  setIsPlaying,
  setPlayBackPosition,
  setPlayBackDuration,
  setMasterVolume,
  setCurrentTrack,
  setDidJustFinish,
  setIsPreparingZipDownload,
} = playerSlice.actions

export default playerSlice.reducer
