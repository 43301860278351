import React from 'react'
import { useSelector } from 'react-redux'
import { Image, TouchableWithoutFeedback } from 'react-native'
import { useDesktopMediaQuery } from '../../utils/hooks/useMediaQueries'
import { Header, MainWrapper, ContentWrapper } from './Layout.styles'
import { ScrollView } from 'react-native'
import { RootState } from 'store'
import Menu from './Menu/Menu'

export default function Layout({ navigation, children }) {
  const isDesktop = useDesktopMediaQuery()
  const { preview } = useSelector((state: RootState) => state.player)

  const handleLogoIconPress = () => {
    navigation.navigate('player', {
      shareId: preview.share_id,
    })
  }

  return (
    <MainWrapper>
      <ScrollView>
        <ContentWrapper $isDesktop={isDesktop}>
          <Header>
            <TouchableWithoutFeedback onPress={handleLogoIconPress}>
              <Image
                source={require('assets/images/logo.png')}
                style={{ width: 150, height: 40 }}
                resizeMode="contain"
              />
            </TouchableWithoutFeedback>
            <Menu navigation={navigation} />
          </Header>
          {children}
        </ContentWrapper>
      </ScrollView>
    </MainWrapper>
  )
}
