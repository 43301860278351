import { FlatList } from 'react-native'
import { DrawerItem } from '@react-navigation/drawer'
import { useTheme } from 'styled-components/native'

export default function LanguagesList({ items }) {
  const theme = useTheme()

  return (
    <FlatList
      data={items}
      keyExtractor={(_, index) => index.toString()}
      renderItem={({ item, index }) => (
        <DrawerItem
          key={index}
          label={item}
          labelStyle={{
            fontFamily: 'NunitoSans',
            fontSize: 16,
            color: theme.colors.text,
          }}
          onPress={() => console.log(item)}
        />
      )}
    />
  )
}
