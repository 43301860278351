import React from 'react'
import { useTheme } from 'styled-components/native'
import Slider from '@react-native-community/slider'
import { VolumeIcon } from './VolumeSlider.styles'
import { Wrapper } from './VolumeSlider.styles'

type VolumeSliderProps = {
  style?: React.CSSProperties
  onSliderChange: (value: number) => void
}

export default function VolumeSlider({
  style,
  onSliderChange,
}: VolumeSliderProps) {
  const theme = useTheme()

  return (
    <Wrapper style={{ ...style }}>
      <VolumeIcon name="volume-medium-outline" size={32} color="white" />
      <Slider
        minimumValue={0}
        maximumValue={1}
        minimumTrackTintColor={theme.colors.cerulean}
        maximumTrackTintColor={theme.colors.text}
        value={1}
        onValueChange={onSliderChange}
      />
    </Wrapper>
  )
}
