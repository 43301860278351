import { View } from 'react-native'
import styled from 'styled-components/native'
import { Ionicons as Icon } from '@expo/vector-icons'

export const Wrapper = styled(View)`
  flex-direction: row;
  align-items: center;
  width: 200px;
  margin-right: 20px;
`

export const VolumeIcon = styled(Icon)`
  margin-right: 10px;
`
