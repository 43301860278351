import React from 'react'
import {
  Container,
  CloseButton,
  CloseIcon,
  Message,
  Description,
  Button,
  ButtonText,
  AlertIcon,
  ContentWrapper,
  TextWrapper,
} from './Alert.styles'

type AlertProps = {
  type: string
  message: string
  description?: string
  buttonText?: string
  closable?: boolean
  isMobile: boolean
  isDesktop: boolean
  onPress?: () => void
  onClose?: () => void
}

export default function Alert({
  type,
  message,
  description,
  buttonText,
  closable,
  isMobile,
  isDesktop,
  onPress,
  onClose,
}: AlertProps) {
  let icon

  if (type === 'success') {
    icon = 'alert-circle-outline'
  } else if (type === 'info') {
    icon = 'alert-circle-outline'
  } else if (type === 'warning') {
    icon = 'alert-circle-outline'
  } else if (type === 'error') {
    icon = 'alert-circle-outline'
  }

  return (
    <Container $isDesktop={isDesktop} $type={type}>
      {closable && (
        <CloseButton onPress={onClose}>
          <CloseIcon $type={type} name="close" size={20} />
        </CloseButton>
      )}
      <ContentWrapper>
        <AlertIcon name={icon} size={24} $type={type} />
        <TextWrapper>
          <Message $type={type}>{message}</Message>
          {description && (
            <Description $isMobile={isMobile} $type={type}>
              {description}
            </Description>
          )}
          {buttonText && (
            <Button onPress={onPress}>
              <ButtonText>{buttonText}</ButtonText>
            </Button>
          )}
        </TextWrapper>
      </ContentWrapper>
    </Container>
  )
}
