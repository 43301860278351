import React, { useState } from 'react'
import { Platform } from 'react-native'
import { useSelector, useDispatch } from 'react-redux'
import { RootState, AppDispatch } from 'store'
import Track from './Track'
import {
  TrackListWrapper,
  GridHeaderGroup,
  Serial,
  GridHeader,
  DownloadTitle,
  ListDivider,
  TrackSummary,
  TotalTracks,
  TotalDuration,
  DotDivider,
} from './TrackList.styles'
import usePlayBack from 'features/Player/usePlayBack'
import {
  useTabletAndAboveMediaQuery,
  useDesktopMediaQuery,
} from '../../utils/hooks/useMediaQueries'
import { getTrackDuration, getTotalTracksDuration } from '../../utils/helpers'
import { ACTIVITY_LOG_EVENT_TYPES } from '../../utils/constants'
import { logTrackActivity } from 'features/Session'

type Track = {
  track_id: string
  title: string
  artist: string
  explicit: boolean
  download_mp3_url: string
  download_wav_url: string
}
type handleCopyButtonTypes = {
  text: string
  trackId: string
}

export default function TrackList() {
  const dispatch: AppDispatch = useDispatch()
  const isTabletAbove = useTabletAndAboveMediaQuery()
  const isDesktop = useDesktopMediaQuery()
  const { handleTrackPlay } = usePlayBack()
  const { preview, playback } = useSelector((state: RootState) => state.player)
  const { session } = useSelector((state: RootState) => state.session)
  const totalDuration = getTotalTracksDuration(preview?.total_time)
  const [trackIdFromCopy, setTrackIdFromCopy] = useState('')

  const handleTrackPress = (track: Track, index: number) => {
    handleTrackPlay(track, index)
    dispatch(
      logTrackActivity({
        eventType: ACTIVITY_LOG_EVENT_TYPES.STREAM,
        sessionId: session?.sessionId,
        shareId: preview?.share_id,
        assetTrackId: track?.track_id,
        audioPosition: playback.status.positionMillis || 0,
      })
    )
  }

  const handleCopyButtonClick = async ({
    text,
    trackId,
  }: handleCopyButtonTypes) => {
    if (Platform.OS === 'web') {
      try {
        await navigator.clipboard.writeText(text)
        setTrackIdFromCopy(trackId)
      } catch (err) {
        setTrackIdFromCopy('')
      }
    }
  }

  return (
    <TrackListWrapper>
      {isTabletAbove && (
        <>
          <GridHeaderGroup
            $isTabletAbove={isTabletAbove}
            $isDesktop={isDesktop}
            $isStreamOnly={preview?.stream_only}
          >
            <Serial>#</Serial>
            <GridHeader>TRACKS({preview?.tracks.length})</GridHeader>
            <GridHeader>ISRC</GridHeader>
            <GridHeader style={{ justifySelf: 'center' }}>DURATION</GridHeader>
            {!preview?.stream_only && (
              <DownloadTitle>DOWNLOAD AS</DownloadTitle>
            )}
          </GridHeaderGroup>
          <ListDivider />
        </>
      )}
      {preview?.tracks.map((track, index) => {
        return (
          <Track
            key={index}
            index={index}
            data={track}
            onPress={() => handleTrackPress(track, index)}
            isCurrentTrack={index === playback?.currentTrack?.index}
            isPlaybackPlaying={playback?.status?.isPlaying}
            time={getTrackDuration(track?.time)}
            handleCopyButtonClick={handleCopyButtonClick}
            trackIdFromCopy={trackIdFromCopy}
          />
        )
      })}
      {isTabletAbove && <ListDivider />}
      <TrackSummary>
        <TotalTracks>{preview?.tracks.length} songs</TotalTracks>
        <DotDivider name="dot-single" size={18} />
        <TotalDuration>{totalDuration}</TotalDuration>
      </TrackSummary>
    </TrackListWrapper>
  )
}
