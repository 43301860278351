import { useState } from 'react'
import * as WebBrowser from 'expo-web-browser'
import {
  DrawerContentScrollView,
  DrawerItemList,
  DrawerItem,
} from '@react-navigation/drawer'
import { View } from 'react-native'
import { useTheme } from 'styled-components/native'
import {
  Header,
  BackButton,
  HeaderTitle,
  DrawerItemLabelStyles,
  ForwardIcon,
  ItemWrapper,
} from './CustomDrawer.styles'
import { subMenus } from './subMenus'
import Languages from 'features/Languages'

export default function CustomDrawer(props) {
  const theme = useTheme()
  const [activeMenu, setActiveMenu] = useState('')

  const handlePressButtonAsync = async (url: string) => {
    await WebBrowser.openBrowserAsync(url)
  }

  const handleBackButtonPress = () => {
    if (activeMenu) {
      return setActiveMenu('')
    }
    props.navigation.toggleDrawer()
  }

  return (
    <View style={{ flex: 1 }}>
      <Header>
        <BackButton
          name="arrow-back-ios"
          size={16}
          color={theme.colors.text}
          onPress={handleBackButtonPress}
        />
        <HeaderTitle>
          {subMenus[activeMenu]?.headerText || 'Options'}
        </HeaderTitle>
      </Header>
      <DrawerContentScrollView
        {...props}
        contentContainerStyle={{
          height: '100%',
        }}
      >
        {!activeMenu && (
          <>
            {/* Temporary comment  */}
            {/* <ItemWrapper>
              <DrawerItem
                label="Language"
                labelStyle={DrawerItemLabelStyles}
                style={{ flex: 2 }}
                onPress={() => setActiveMenu('languages')}
              />
              <ForwardIcon
                name="keyboard-arrow-right"
                onPress={() => setActiveMenu('languages')}
              />
            </ItemWrapper> */}

            <DrawerItem
              label="About"
              labelStyle={DrawerItemLabelStyles}
              onPress={() => handlePressButtonAsync('https://www.plaympe.com/')}
            />
            <DrawerItem
              label="Terms and Conditions"
              labelStyle={DrawerItemLabelStyles}
              onPress={() =>
                handlePressButtonAsync('https://www.plaympe.com/terms/')
              }
            />
            <DrawerItem
              label="Contact Support"
              labelStyle={DrawerItemLabelStyles}
              onPress={() =>
                handlePressButtonAsync(
                  'https://support.plaympe.com/portal/en/newticket?departmentId=348910000000006907&layoutId=348910000000074011'
                )
              }
            />
            <DrawerItemList {...props} />
          </>
        )}

        {activeMenu === 'languages' && (
          <Languages items={subMenus[activeMenu].items} />
        )}
      </DrawerContentScrollView>
    </View>
  )
}
