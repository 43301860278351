import { DefaultTheme } from 'styled-components/native'
import { createTheme } from '@rneui/themed'

export type ThemeProp = {
  [key: string]: string | ThemeProp
}

declare module 'styled-components' {
  export interface DefaultTheme {
    [key: string]: ThemeProp
  }
}

const darkTheme: DefaultTheme = {
  colors: {
    body: '#121212',
    text: '#fff',
    gray: '#d6d6d6',
    light_gray: '#eeeeee',
    cerulean: '#009CFF',
    blueberry: '#487cf7',
    chineseBlack: '#171717',
    quartz: '#4A4A4A',
    neutralC80: '#E3E6EA',
    neutralW100: '#F5F5F5',
    neutralW10: '#141414',
    neutralW20: '#222222',
    neutralW30: '#393939',
    neutralW40: '#4F4E4E',
    neutralW50: '#717070',
    neutralW80: '#E0E0E0',
    player50: '#6397e4',
    interact70: '#69ABF1',
    success20: '#1C3B21',
    success70: '#90D79C',
    alert20: '#4B3903',
    alert70: '#FBD872',
    interact20: '#022345',
    error20: '#3B0707',
    error70: '#E95D5D',
  },
}
const lightTheme: DefaultTheme = {
  colors: {
    body: '#fff',
    text: '#000',
    blueberry: '#487cf7',
  },
}

const RNEtheme = createTheme({
  components: {
    Button: {
      radius: 'sm',
      buttonStyle: {
        paddingVertical: 6,
        paddingHorizontal: 16,
      },
      titleStyle: {
        fontFamily: 'NunitoSans',
        fontSize: 14,
      },
      disabledStyle: {
        backgroundColor: darkTheme.colors.neutralW40,
        borderColor: darkTheme.colors.neutralW40,
      },
    },
  },
  lightColors: {
    primary: darkTheme.colors.player50,
    secondary: darkTheme.colors.text,
  },
  darkColors: {
    primary: '#000',
  },
  mode: 'light',
})

export { lightTheme, darkTheme, RNEtheme }
